import React, { FC } from 'react';
import { Label, LabelProps } from '@stenajs-webui/elements';
import { TextInput, TextInputProps } from '@stenajs-webui/forms';
import { Column } from '@stenajs-webui/core';

interface Props extends TextInputProps {
  label?: string;
  labelProps?: LabelProps;
  style?: React.ComponentProps<'div'>['style'];
}

const Input: FC<Props> = (props) => {
  const { label, labelProps, style, ...inputProps } = props;

  return (
    <Column>
      <Label text={label ?? ''} htmlFor={inputProps.name} {...labelProps}>
        <TextInput {...inputProps} />
      </Label>
    </Column>
  );
};

export default Input;
