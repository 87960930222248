import UrlResolver from './UrlResolver';

/**
 * @description This class is used for TAPI related requests.
 */
export default class TapiService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = UrlResolver.getTapiUrl();
  }

  getOrder(bookingReference: string, licensePlate: string): Promise<Response> {
    const url = `${this.baseUrl}/stenaline/order?bookingReference=${bookingReference}&licensePlate=${licensePlate}`;
    return fetch(url);
  }

  getClaim(orderId: string, claimedBy: string): Promise<Response> {
    const url = `${this.baseUrl}/stenaline/claim?orderId=${orderId}&claimedBy=${claimedBy}`;
    return fetch(url);
  }

  signJwt(params: {
    orderInitiatorId: string;
    orderNumber: string;
    customerIndex: string;
    firstName: string;
    lastName: string;
    email: string;
  }): Promise<Response> {
    const queryParams = Object.entries(params).reduce(
      (result, [key, value], index) => `${result}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`,
      ''
    );
    const url = `${this.baseUrl}/stenaline/sign/jwt${queryParams}`;
    return fetch(url);
  }
}
