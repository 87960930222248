import { Box, SeparatorLine, Text } from '@stenajs-webui/core';
import { PrimaryButton } from '@stenajs-webui/elements';
import { CenterModal, CenterModalProps } from '@stenajs-webui/modal';
import React from 'react';
import { FC } from 'react';

interface Props extends CenterModalProps {
  headerText?: string;
  text?: string;
}

const ErrorModal: FC<Props> = (props) => {
  const { headerText, text, ...modalProps } = props;

  return (
    <CenterModal {...modalProps}>
      <Box style={{ textAlign: 'center' }}>
        <Text variant="bold" style={{ padding: '1em 4em', fontSize: '24px' }}>
          {headerText}
        </Text>
        <SeparatorLine />
        <Text style={{ padding: '1.5em', fontSize: '20px' }}>{text}</Text>
        <Box style={{ padding: '1.5em 4em' }}>
          <PrimaryButton label="Close" onClick={modalProps.onRequestClose} />
        </Box>
      </Box>
    </CenterModal>
  );
};

export default ErrorModal;
