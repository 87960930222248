import React, { FC } from 'react';
import { Box } from '@stenajs-webui/core';
import StenaFlag from './StenaFlag';

const Logo: FC = () => {
  return (
    <Box style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -100%)', width: 100, top: -50 }}>
      <StenaFlag />
    </Box>
  );
};

export default Logo;
