export function objectToQueryParams(obj: Record<string, string | number | boolean>) {
  return Object.entries(obj).reduce((result, [key, value], index) => `${result}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`, '');
}

export function transformValues<T extends object>(obj: T, transformer: (value: T[keyof T]) => any) {
  return Object.entries(obj).reduce<T>((prev, [key, value]) => ({ ...prev, [key]: transformer(value) }), obj);
}

export const getOrderInitiatorEmail = (order: any) => {
  let orderInitiator = order.parties.orderInitiator;
  if (orderInitiator && orderInitiator.contactMeans) {
    const emailContactMeans = orderInitiator.contactMeans.find((contact: any) => contact.type === 'email');
    if (emailContactMeans && emailContactMeans.value) {
      return emailContactMeans.value;
    }
  }

  return '';
};
