import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Column, Heading } from '@stenajs-webui/core';
import { PrimaryButton } from '@stenajs-webui/elements';
import React, { FC, useCallback, useState } from 'react';
import { Order } from '../App';
import TapiService from '../modules/TapiService';
import { transformValues } from '../utils/utils';
import Form from './Form';
import Input from './Input';

export interface BookingDetails {
  bookingReference: string;
  licensePlate: string;
}

interface Props {
  tapiService: TapiService;
  onResponse: (order: Order | undefined) => void;
}

const BookingDetailsForm: FC<Props> = (props) => {
  const { onResponse, tapiService } = props;

  const [loading, setLoading] = useState(false);
  const [bookingReference, setBookingReference] = useState('');
  const [licensePlate, setLicensePlate] = useState('');

  const handleSubmit = useCallback((data: BookingDetails) => {
    setLoading(true);
    tapiService
      .getOrder(data.bookingReference, data.licensePlate)
      .then((response) => response.json())
      .then((body) => {
        setLoading(false);
        const { order } = body;
        onResponse(order);
      });
  }, []);

  return (
    <Form
      onSubmit={(model: BookingDetails) => {
        if (!model.bookingReference || !model.licensePlate) {
          return;
        }
        handleSubmit(transformValues(model, (value) => value.trim()));
      }}
    >
      <Column gap={6}>
        <Heading variant="h2">Log in to make a claim</Heading>
        <Input
          required
          name="bookingReference"
          label="Booking reference"
          value={bookingReference}
          onBlur={(event) => setBookingReference(event.target.value.trim())}
          onChange={(event) => setBookingReference(event.target.value)}
        />
        <Input
          required
          name="licensePlate"
          label="Vehicle reg/Trailer reg"
          value={licensePlate}
          onBlur={(event) => setLicensePlate(event.target.value.trim())}
          onChange={(event) => setLicensePlate(event.target.value.toUpperCase())}
        />
        <PrimaryButton loading={loading} loadingLabel={'Signing in...'} leftIcon={faLock} type="submit" label="Sign in" />
      </Column>
    </Form>
  );
};

export default BookingDetailsForm;
