const IP_REGEXP = new RegExp(
  '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
    '\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
    '\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
    '\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
);

const TENANT = 'stena';

export default class UrlResolver {
  static getBackendUrl(clientLoc: Location) {
    let result;
    const apiOverride = this._getParameterByName('api', clientLoc);
    if (apiOverride) {
      // overridden scenario
      return apiOverride;
    } else if (UrlResolver.validateIPaddress(clientLoc.hostname) || clientLoc.hostname.endsWith('xip.io') || clientLoc.hostname === 'localhost') {
      // localhost dev env scenario
      result = `${clientLoc.hostname}:3091`;
    } else if (clientLoc.hostname.endsWith('apac.fatikat.com')) {
      result = 'api.lx.apac.fatikat.com';
    } else if (clientLoc.hostname.endsWith('.fatikat.com')) {
      // *.fatikat.com scenario
      result = 'stena.api.blx.fatikat.com';
    } else if (clientLoc.hostname.endsWith('.stagikat.com')) {
      // *.fatikat.com scenario
      result = 'api.lx.stagikat.com';
    } else if (clientLoc.hostname.endsWith('.testikat.com')) {
      // *.testikat.com scenario
      result = 'api.blx.testikat.com';
    } else {
      // prod scenario
      result = 'stena.api.svc.tolynx.com';
    }

    return `${clientLoc.protocol}//${result}`;
  }

  static getTapiUrl() {
    if (window.location.hostname.endsWith('lx.fatikat.com')) {
      return `//api.stena.blx.fatikat.com`;
    } else if (window.location.hostname.endsWith('.apac.fatikat.com')) {
      return `//api.stena.blx.apac.fatikat.com`;
    } else if (window.location.hostname.endsWith('lx.stagikat.com')) {
      return `//api.stena.blx.stagikat.com`;
    } else if (window.location.hostname.endsWith('.apac.stagikat.com')) {
      return `//api.stena.blx.apac.stagikat.com`;
    } else if (window.location.hostname.endsWith('apac.tolynx.com')) {
      return `//api.stena.apac.tolynx.com`;
    } else if (window.location.hostname.endsWith('tolynx.com')) {
      return `//api.stena.tolynx.com`;
    } else if (window.location.hostname.endsWith('bylynx.com')) {
      return `//api.stena.tolynx.com`;
    }
    return '//localhost:3010';
  }

  static getClientUrl() {
    if (window.location.hostname.endsWith('blx.fatikat.com')) {
      return '//stena.lx.fatikat.com';
    } else if (window.location.hostname.endsWith('blx.stagikat.com')) {
      return '//stena.lx.stagikat.com';
    } else if (window.location.hostname.endsWith('tolynx.com')) {
      return '//stena.tolynx.com';
    }

    return `//${window.location.hostname}:4000`;
  }

  static validateIPaddress(ipaddress: any) {
    return IP_REGEXP.test(ipaddress);
  }

  static _getParameterByName(paramName: any, search: any) {
    const qry = search;
    const name = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(qry);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
