import { Column } from '@stenajs-webui/core';
import { BannerProps, Card, CardBody } from '@stenajs-webui/elements';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BookingDetailsForm from './components/BookingDetailsForm';
import Modal from './components/Modal';
import Logo from './components/Logo';
import PartyDetailsForm, { PartyDetails } from './components/PartyDetailsForm';
import TapiService from './modules/TapiService';
import UrlResolver from './modules/UrlResolver';
import Loading from './components/Loading';

const tapiService = new TapiService();

function generateServiceUrl(data: { orderNumber: string; jwt: string; customerIndex: string; claim?: any }) {
  const { orderNumber, jwt, customerIndex } = data;

  const serviceJson: Record<string, any> = { order: { id: orderNumber, cust: { id: customerIndex } } };

  if (data.claim) {
    const claim = data.claim;
    serviceJson.claim = {
      id: claim.id
    };
  }

  const serviceParams = encodeURIComponent(JSON.stringify(serviceJson));

  const clientUrl = UrlResolver.getClientUrl();
  const serverUrl = UrlResolver.getBackendUrl(window.location);

  return `${clientUrl}?api=${serverUrl}&tenant=stenaline&service=lynx.claim.register&serviceParams=${serviceParams}&jwt=${jwt}`;
}

export type Order = any;

enum ResponseStatus {
  NotFound = 'not_found',
  Generic = 'generic'
}

enum Stages {
  BookingDetails = 'booking_details',
  PartyDetails = 'party_details',
  ClaimExists = 'claim_exists'
}

const responseMap: Record<ResponseStatus, BannerProps> = {
  [ResponseStatus.NotFound]: {
    headerText: 'Booking not found',
    text: 'Please check your input'
  },
  [ResponseStatus.Generic]: {
    headerText: 'Something went wrong',
    text: 'Could not confirm details'
  }
};

const App = () => {
  const [order, setOrder] = useState<Order | undefined>();
  const [claim, setClaim] = useState<any | undefined>();
  const [responseStatus, setResponseStatus] = useState<ResponseStatus | null>(null);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState<Stages>(Stages.BookingDetails);

  const appRef = useRef<HTMLDivElement | null>(null);

  const openServiceUrl = useCallback((orderNumber: string, jwt: string, customerIndex: string, claim?: any) => {
    window.open(generateServiceUrl({ orderNumber, jwt, customerIndex, claim }), '_self');
  }, []);

  const orderNumber = useMemo(() => order?.identifiers?.find?.((identifier: any) => identifier.domain === 'order')?.identifier ?? '', [order]);
  const customerIndex = useMemo(() => order?.parties?.orderInitiator?.id, [order]);
  const customerName = useMemo(() => order?.parties?.orderInitiator?.name2, [order]);

  const handleSubmit = useCallback(
    (model: PartyDetails) => {
      setLoading(true);
      tapiService
        .signJwt({ orderInitiatorId: customerName, orderNumber, customerIndex, ...model })
        .then((response) => response.json())
        .then(async (body) => {
          setLoading(false);
          const { jwt } = body;
          if (!jwt) {
            setResponseStatus(ResponseStatus.Generic);
            return;
          }

          openServiceUrl(orderNumber, jwt, customerIndex, claim);
        });
    },
    [order, tapiService]
  );

  useEffect(() => {
    if (claim) {
      const claimInitiator = claim.parties.find((party: any) => party.role === 'claim_initiator');
      const firstName = claimInitiator?.name;
      const lastName = claimInitiator?.name2;
      const email = claimInitiator?.contactMeans?.find((contact: any) => contact.type === 'email')?.value;
      handleSubmit({
        email,
        firstName,
        lastName
      });
    }
  }, [claim]);

  return (
    <div ref={appRef}>
      <Column width={500} position="absolute" top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)' }}>
        <Logo />
        <Card
          style={{
            alignItems: 'center',
            paddingTop: '5em',
            paddingBottom: '13em'
          }}
        >
          <CardBody style={{ width: '65%', textAlign: 'center' }}>
            {
              {
                [Stages.BookingDetails]: (
                  <BookingDetailsForm
                    tapiService={tapiService}
                    onResponse={async (order) => {
                      if (!order) {
                        setResponseStatus(ResponseStatus.NotFound);
                        return;
                      }

                      setOrder(order);

                      const orderNumber = order?.identifiers?.find?.((identifier: any) => identifier.domain === 'order')?.identifier ?? '';
                      const customerIndex = order?.parties?.orderInitiator?.id;

                      const { claim } = await tapiService.getClaim(orderNumber, customerIndex).then((response) => response.json());

                      if (claim && typeof claim === 'object') {
                        setClaim(claim);
                        setStage(Stages.ClaimExists);
                      } else {
                        setStage(Stages.PartyDetails);
                      }
                    }}
                  />
                ),
                [Stages.PartyDetails]: <PartyDetailsForm loading={loading} handleSubmit={handleSubmit} />,
                [Stages.ClaimExists]: <Loading />
              }[stage]
            }
          </CardBody>
        </Card>
        <Modal
          appElement={appRef.current || undefined}
          isOpen={Boolean(responseStatus)}
          onRequestClose={() => setResponseStatus(null)}
          {...(responseStatus ? responseMap[responseStatus] : {})}
        />
      </Column>
    </div>
  );
};

export default App;
