import React, { FC } from 'react';
import { Box } from '@stenajs-webui/core';
import { Spinner } from '@stenajs-webui/elements';

const Loading: FC = () => {
  return (
    <Box
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      <Spinner />
    </Box>
  );
};

export default Loading;
