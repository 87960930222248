import { Column, Heading } from '@stenajs-webui/core';
import { PrimaryButton } from '@stenajs-webui/elements';
import React, { FC, useState } from 'react';
import Form from './Form';
import Input from './Input';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { transformValues } from '../utils/utils';

export interface PartyDetails {
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  loading: boolean;
  handleSubmit: (response: PartyDetails) => void;
}

const PartyDetailsForm: FC<Props> = (props) => {
  const { loading, handleSubmit } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  return (
    <Form
      onSubmit={(model: PartyDetails) => {
        if (!model.firstName || !model.lastName || !model.email) {
          return;
        }
        handleSubmit(transformValues(model, (value) => value.trim()));
      }}
    >
      <Column gap={6}>
        <Heading variant="h2">Confirm details</Heading>
        <Input
          required
          name="firstName"
          label="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onBlur={(e) => setFirstName(e.target.value.trim())}
        />
        <Input
          required
          name="lastName"
          label="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onBlur={(e) => setLastName(e.target.value.trim())}
        />
        <Input required name="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={(e) => setEmail(e.target.value.trim())} />
        <PrimaryButton loading={loading} loadingLabel="Confirming..." leftIcon={faArrowUpRightFromSquare} type="submit" label="Go to claim" />
      </Column>
    </Form>
  );
};

export default PartyDetailsForm;
