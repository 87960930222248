
const digest = 'f1d57cc6aeb3d6b2afd33cdf8a6890535b2a5f0c209ce15f0b7117c8d12cdf84';
const css = `body,
html,
* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f4f4f4;
}

@font-face {
  font-family: 'Stena Sans';
  src:
    local('StenaSansWebLight'),
    url('./assets/fonts/StenaSansWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Stena Sans';
  src:
    local('StenaSansWebRegular'),
    url('./assets/fonts/StenaSansWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Stena Sans';
  src:
    local('StenaSansWebMedium'),
    url('./assets/fonts/StenaSansWeb-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Stena Sans';
  src:
    local('StenaSansWebBold'),
    url('./assets/fonts/StenaSansWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

:root {
  --swui-font-weight-text: 500;
  --swui-font-weight-text-bold: 700;
  --swui-font-size-small: 1.4rem;
  --swui-font-size-medium: 1.6rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  