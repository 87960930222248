import React, { FC } from 'react';

function serializeForm(form: HTMLFormElement) {
  const formData = new FormData(form);
  return Object.fromEntries(formData.entries()) as any;
}

interface Props extends React.ComponentProps<'form'> {
  onSubmit?: (model: any) => void;
}

const Form: FC<Props> = (props) => {
  const { children, onSubmit, ...restProps } = props;
  return (
    <form
      {...restProps}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(serializeForm(e.currentTarget));
      }}
    >
      {children}
    </form>
  );
};

export default Form;
